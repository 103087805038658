import httpClient from 'api/http-client';

export type ContactState = {
    firstInteractionTime: number | null;
    lastInteractionTime: number | null;
    lastInteractionType: 'call' | 'sms' | null;
    isLastCallAnswered: boolean | null;
    isLastCallEnd: boolean | null;
    userEverReplySms: boolean | null;
    userEverAnswerCall: boolean | null;
    lastUserAnswerCallTime: number | null;
    lastUserSmsTime: number | null;
    lastUserInteractionTime: number | null;
    lastUserInteractionType: 'call' | 'sms' | null;
    userEverInteraction: boolean | null;
    numberOfCalls: number;
    numberOfAssistantSms: number;
    numberOfUserSms: number;
    isSmsOnlyCollectionsMissing: boolean | null;
    daysSinceFirstInteraction: number | null;
    userEverInteractionToday: boolean | null;
    currentHourLocal: number | null;
    lastAssistantSmsTime: number | null;
    numberOfCallsToday: number;
    numberOfSmsToday: number;
    numberOfAnsweredCallsToday: number;
    numberOfUserSmsToday: number;
    lastCallTime: number | null;
};

export type ContactStateBasic = {
    firstInteractionTime?: number | null;
    lastInteractionTime?: number | null;
    lastInteractionType?: 'call' | 'sms';
    isLastCallAnswered?: boolean;
    isLastCallEnd?: boolean;
    userEverReplySms?: boolean;
    userEverAnswerCall?: boolean;
    lastUserAnswerCallTime?: number;
    lastUserSmsTime?: number;
    lastUserInteractionTime?: number;
    lastUserInteractionType?: 'call' | 'sms';
    userEverInteraction?: boolean;
    userEverInteractionToday?: boolean;
    isSmsOnlyCollectionsMissing?: boolean | null;
};

export type ContactStateNumeric = {
    numberOfCalls?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfAssistantSms?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfUserSms?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    daysSinceFirstInteraction?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    currentHourLocal?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfCallsToday?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfSmsToday?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfAnsweredCallsToday?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfUserSmsToday?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
};

export type ContactStateOptional = ContactStateBasic & ContactStateNumeric;

type TimeUnit = 's' | 'm' | 'h' | 'd';
type TimeIntervalString = `${number}${TimeUnit}`;
type TimeIntervalContition = {
    timeToCheck:
        | 'firstInteractionTime'
        | 'lastInteractionTime'
        | 'lastUserInteractionTime'
        | 'lastAssistantSmsTime'
        | 'lastCallTime';
    startIntervalTime: TimeIntervalString | null;
    endIntervalTime: TimeIntervalString | null;
    type?: 'user-friendly' | 'days-different';
};

export type NextStepRule = {
    description: string;
    conditions: {
        timeIntervalConditions: TimeIntervalContition[];
    } & ContactStateOptional;
    do: {
        nextStep: 'doCall' | 'doSms' | 'doNothing' | 'doScheduleSms' | 'doScheduleCall';
        type?: 'random';
        startHour?: number;
        endHour?: number;
    };
};

export type NextStepRuleParameterDescription = {
    [key in keyof ContactState]: {
        name: string;
        description: string;
    };
};

export interface AiConfig {
    nextStepRuleParameterDescription: NextStepRuleParameterDescription;
    nextStepRule: NextStepRule[];
    sequenceId: string;
    sequenceName: string;
    sequenceLabel: string;
}

const getAiConfig = async (): Promise<AiConfig[]> => {
    const { data } = await httpClient.post<AiConfig[]>('/api/get-ai-config');
    return data;
};

export default getAiConfig;
