import React from 'react';
import { NextStepRule, NextStepRuleParameterDescription } from 'api/getAiConfig';
import FieldValue from './FieldValue';

const operatorMap = {
    eq: '=',
    gt: '>',
    lt: '<',
    gte: '>=',
    lte: '<='
};

function AutomationRule({
    nextStepRuleParameterDescription,
    automationRule
}: {
    nextStepRuleParameterDescription: NextStepRuleParameterDescription;
    automationRule: NextStepRule;
}) {
    return (
        <div className="flex flex-col gap-2">
            <div className="text-lg">
                <span className="font-medium">Rule: </span>
                <span className="font-medium">{automationRule.description}</span>
            </div>
            <div className="flex flex-col justify-between">
                <div className="flex flex-col gap-2">
                    <span className="flex font-semibold">Interaction Conditions</span>
                    <div className="flex">
                        <div className="flex flex-col rounded border-2 border-gray-400 px-2">
                            {Object.keys(automationRule.conditions).map(keyName => {
                                if (keyName === 'timeIntervalConditions') {
                                    return null;
                                } else if (keyName in nextStepRuleParameterDescription) {
                                    const keyNameTyped = keyName as keyof typeof nextStepRuleParameterDescription;
                                    const desObj = nextStepRuleParameterDescription[keyNameTyped];
                                    const conditionValue =
                                        automationRule.conditions[keyName as keyof typeof automationRule.conditions];
                                    if (
                                        [
                                            'numberOfCalls',
                                            'numberOfAssistantSms',
                                            'numberOfUserSms',
                                            'daysSinceFirstInteraction',
                                            'currentHourLocal',
                                            'numberOfCallsToday',
                                            'numberOfSmsToday',
                                            'numberOfAnsweredCallsToday',
                                            'numberOfUserSmsToday'
                                        ].includes(keyName)
                                    ) {
                                        const { operator, value } = conditionValue as {
                                            operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
                                            value: number;
                                        };
                                        return (
                                            <FieldValue
                                                key={keyName}
                                                field={`${desObj.name}`}
                                                value={`${operatorMap[operator]} ${value}`}
                                                fieldTooltip={desObj.description}
                                                valueToolTip={null}
                                            />
                                        );
                                    } else {
                                        return (
                                            <FieldValue
                                                key={keyName}
                                                field={`${desObj.name}`}
                                                value={String(conditionValue)}
                                                fieldTooltip={desObj.description}
                                                valueToolTip={null}
                                            />
                                        );
                                    }
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <span className="font-semibold">Time Intervals Conditions (All conditions must be met)</span>
                <div className="flex gap-2">
                    {automationRule.conditions.timeIntervalConditions.map(condition => (
                        <div key={condition.timeToCheck} className="my-2 flex rounded border-2 border-gray-400 px-2">
                            <div className="flex flex-col gap-1">
                                <FieldValue
                                    field="Baseline Time"
                                    value={nextStepRuleParameterDescription[condition.timeToCheck].name}
                                    fieldTooltip={null}
                                    valueToolTip={nextStepRuleParameterDescription[condition.timeToCheck].description}
                                />
                                {condition.startIntervalTime ? (
                                    <FieldValue
                                        field="Start At"
                                        value={`${condition.startIntervalTime} after the baseline`}
                                        fieldTooltip={null}
                                        valueToolTip={null}
                                    />
                                ) : (
                                    <FieldValue
                                        field="Start At"
                                        value="starts immediately"
                                        fieldTooltip={null}
                                        valueToolTip={null}
                                    />
                                )}
                                {condition.endIntervalTime ? (
                                    <FieldValue
                                        field="End At"
                                        value={`${condition.endIntervalTime} after the baseline`}
                                        fieldTooltip={null}
                                        valueToolTip={null}
                                    />
                                ) : (
                                    <FieldValue
                                        field="End At"
                                        value="never ends"
                                        fieldTooltip={null}
                                        valueToolTip={null}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex gap-4">
                <span className="font-semibold">What To Do (if all conditions are met))</span>
                <span className="flex gap-2 text-blue-600">{automationRule.do.nextStep}</span>
            </div>
        </div>
    );
}

export default AutomationRule;
