import httpClient from 'api/http-client';
import { DbContact } from 'types/dbContact';

export interface SequenceArray {
    sequenceId: string;
    sequenceName: string;
    sequenceLabel: string;
}

export interface Response {
    result: DbContact[];
    total: number;
    aiEnabled: boolean;
    intakeStatusArray: string[];
    aiEnabledAutoTakeContact: boolean;
    autoTakeContactRatio: number;
    companySequenceArray: SequenceArray[];
}

export const NORMAL_AI_STATUS = 'Normal';

type FilterModelItemTextInPayload = {
    colId: string;
    filterType: 'text';
    type: 'contains';
    filter: string | null;
};

type FilterModelItemDateInPayload = {
    colId: string;
    filterType: 'date';
    type: 'inRange';
    dateFrom: number | null;
    dateTo: number | null;
};

type FilterModelItemUuidInPayload = {
    colId: string;
    filterType: 'uuid';
    id: string | null;
};

export type FilterModelItemInPayload = (
    | FilterModelItemTextInPayload
    | FilterModelItemDateInPayload
    | FilterModelItemUuidInPayload
)[];

const getContacts = async (payload: {
    search: string | null;
    page: number;
    pageSize: number;
    sortFields: string[];
    sortOrders: ('asc' | 'desc')[];
    filters: FilterModelItemInPayload;
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/get-db-contact', payload);
    return data;
};

export default getContacts;
