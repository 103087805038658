import React from 'react';
import { ColDef } from 'ag-grid-community';
import { PhoneIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChatBubbleIcon } from '@radix-ui/react-icons';
import HubspotButtonComponent from '../HubspotButtonComponent';
import IntakeStatusSelect from '../IntakeStatusSelect';
import AiEnabledButton from '../AiEnabledButton';
import ScheduledAtComponent from '../ScheduledAtComponent';
import ReasonComponent from '../ReasonComponent';
import ViewButtonComponent from '../ViewButtonComponent';

export const columnDefinitions: ColDef[] = [
    { field: 'hubspotContactId', cellRenderer: HubspotButtonComponent, sortable: false },
    {
        headerName: 'name',
        sortable: false,
        width: 120,
        cellRenderer: function (data: {
            data: { firstname: string | null | undefined; lastname: string | null | undefined };
        }) {
            return (
                <div>
                    {data.data.firstname ?? data.data.firstname} {data.data.lastname ?? data.data.lastname}
                </div>
            );
        }
    },
    {
        headerName: 'First contact',
        sortable: false,
        field: 'firstInteractionAt',
        cellRenderer: function (data: {
            data: { firstInteractionAt: number | null; firstInteractionType: 'sms' | 'call' | null };
        }) {
            if (data.data.firstInteractionAt === null) {
                return (
                    <div className="flex justify-center">
                        <div className="">n/a</div>
                    </div>
                );
            } else {
                const date = new Date(data.data.firstInteractionAt);
                return (
                    <div className="flex h-10 items-center justify-center gap-1 text-sm">
                        {data.data.firstInteractionType === 'call' ? (
                            <PhoneIcon className="h-4 w-4" />
                        ) : (
                            <ChatBubbleIcon className="h-4 w-4" />
                        )}
                        {date.toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        })}
                    </div>
                );
            }
        }
    },
    {
        headerName: 'Engaged',
        sortable: false,
        field: 'userEverInteraction',
        cellRenderer: function (data: { data: { userEverInteraction: boolean | null } }) {
            if (data.data.userEverInteraction === null) {
                return (
                    <div className="flex justify-center">
                        <div className="">n/a</div>
                    </div>
                );
            } else if (data.data.userEverInteraction) {
                return (
                    <div className="flex h-10 items-center justify-center text-lg text-green-600">
                        <CheckIcon className="h-4 w-4" />
                    </div>
                );
            } else {
                return (
                    <div className="flex h-10 items-center justify-center text-lg text-red-600">
                        <XMarkIcon className="h-4 w-4" />
                    </div>
                );
            }
        }
    },
    {
        headerName: 'Last Engagement',
        sortable: false,
        field: 'userLastInteractionAt',
        cellRenderer: function (data: {
            data: { userLastInteractionAt: number | null; userLastInteractionType: 'sms' | 'call' | null };
        }) {
            if (data.data.userLastInteractionAt === null) {
                return (
                    <div className="flex justify-center">
                        <div className="">n/a</div>
                    </div>
                );
            } else {
                const date = new Date(data.data.userLastInteractionAt);
                return (
                    <div className="flex h-10 items-center justify-center gap-1 text-sm">
                        {data.data.userLastInteractionType === 'call' ? (
                            <PhoneIcon className="h-4 w-4" />
                        ) : (
                            <ChatBubbleIcon className="h-4 w-4" />
                        )}
                        {date.toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        })}
                    </div>
                );
            }
        }
    },
    {
        headerName: 'Intake Status',
        sortable: false,
        field: 'intakeStatus',
        cellRenderer: IntakeStatusSelect,
        cellRendererParams: { intakeStatusArray: [] }
    },
    {
        headerName: 'Intake Completion',
        sortable: false,
        field: 'intakeCompletionScore',
        cellRenderer: function (data: { data: { intakeCompletionScore: number } }) {
            return `${(data.data.intakeCompletionScore * 100).toFixed(0)}%`;
        }
    },
    {
        headerName: 'ai follow ups',
        field: 'aiEnabled',
        sortable: false,
        cellRenderer: AiEnabledButton,
        cellRendererParams: { fieldName: 'aiEnabled' }
    },
    {
        headerName: 'ai responses',
        sortable: false,
        field: 'aiEnabledReplySmsCall',
        cellRenderer: AiEnabledButton,
        cellRendererParams: { fieldName: 'aiEnabledReplySmsCall' }
    },
    {
        headerName: 'next step',
        sortable: false,
        field: 'nextStepDetailNextStep',
        cellRenderer: function (data: {
            data: { nextStepDetailNextStep: string | null; intakeStatus: string | null };
        }) {
            if (data.data.nextStepDetailNextStep === null) {
                return null;
            } else if (data.data.nextStepDetailNextStep === 'doCall') {
                return (
                    <div className="flex h-10 w-10 items-center justify-center">
                        <PhoneIcon className="h-4 w-4 fill-gray-400 text-gray-400" />
                    </div>
                );
            } else if (data.data.nextStepDetailNextStep === 'doSms') {
                return (
                    <div className="flex h-10 w-10 items-center justify-center">
                        <ChatBubbleIcon className="h-4 w-4" />
                    </div>
                );
            } else if (data.data.nextStepDetailNextStep === 'doNothing') {
                return <div className="">No next step</div>;
            } else {
                return <div>n/a</div>;
            }
        }
    },
    {
        headerName: 'scheduled at',
        sortable: false,
        field: 'nextStepDetailScheduledAt',
        cellRenderer: ScheduledAtComponent
    },
    {
        headerName: 'reason',
        sortable: false,
        field: 'nextStepDetailReason',
        cellRenderer: ReasonComponent
    },
    { field: 'phone', sortable: false },
    { field: 'email', sortable: false },
    {
        headerName: 'View',
        sortable: false,
        field: 'action',
        cellRenderer: ViewButtonComponent,
        pinned: 'right'
    }
];
