import React, { useEffect, useState } from 'react';
import Contacts from './Contacts/Contacts';
import ContactsHubspot from './Contacts/ContactsHubspot';
import AiConfigComp from './AiConfig';

function MainPage() {
    const [selectedTab, setSelectedTab] = useState('db');

    return (
        <div className="h-full w-full px-4 sm:px-6 lg:px-8">
            <div className="h-[5rem]">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Contacts</h1>
                    </div>
                </div>
                <div className="flex gap-4">
                    <button
                        className={`rounded px-2 py-1 ${selectedTab === 'db' ? 'bg-blue-300' : 'bg-gray-200'}`}
                        onClick={() => setSelectedTab('db')}
                    >
                        AI Handled Contacts
                    </button>
                    <button
                        className={`rounded px-2 py-1 ${selectedTab === 'ai-config' ? 'bg-blue-300' : 'bg-gray-200'}`}
                        onClick={() => setSelectedTab('ai-config')}
                    >
                        AI Automatic Followup Rules
                    </button>
                    <button
                        className={`rounded px-2 py-1 ${selectedTab === 'hubspot' ? 'bg-blue-300' : 'bg-gray-200'}`}
                        onClick={() => setSelectedTab('hubspot')}
                    >
                        Hubspot Contacts
                    </button>
                </div>
            </div>

            {selectedTab === 'hubspot' && <ContactsHubspot />}
            {selectedTab === 'db' && <Contacts />}
            {selectedTab === 'ai-config' && <AiConfigComp />}
        </div>
    );
}

export default MainPage;
