import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { NORMAL_AI_STATUS, SequenceArray } from 'api/getContacts';

export type FilterModelItemText = {
    colId: string;
    label: string;
    filterType: 'text';
    type: 'contains';
    filter: string | null;
};

export type FilterModelItemDate = {
    colId: string;
    label: string;
    filterType: 'date';
    type: 'inRange';
    dateFrom: number | null;
    dateTo: number | null;
};

export type FilterModelItemUuid = {
    colId: string;
    label: string;
    filterType: 'uuid';
    id: string | null;
};

export type FilterModelItem = FilterModelItemText | FilterModelItemDate | FilterModelItemUuid;

type props = {
    companySequenceArray: SequenceArray[];
    filterModel: FilterModelItem[];
    intakeStatusArray: string[];
    onFilterChange: (
        colId: string,
        datetimeFrom: number | null,
        datetimeTo: number | null,
        textFilter?: string | null
    ) => void;
};

function Filter(props: props) {
    const [curColId, setCurColId] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<{ start: Dayjs | null; end: Dayjs | null }>({ start: null, end: null });
    const [error, setError] = useState<null | string>(null);
    const [textFilter, setTextFilter] = useState<string | null>(null);

    // open modal to filter by date or text
    const handleOpenModal = (colId: string) => {
        setCurColId(colId);
        const filter = props.filterModel.find(f => f.colId === colId);
        if (filter) {
            if (filter.filterType === 'text') {
                setTextFilter(filter.filter || null);
            } else if (filter.filterType === 'uuid') {
                setTextFilter(filter.id || null);
            }
        }
    };

    const handleCloseModal = () => {
        setCurColId(null);
        setTextFilter(null);
    };

    const handleClearModal = () => {
        if (curColId) {
            const filter = props.filterModel.find(f => f.colId === curColId);
            if (filter?.filterType === 'date') {
                setDateRange({ start: null, end: null });
            } else if (filter?.filterType === 'text') {
                setTextFilter(null);
            }
        }
    };

    const handleApplyFilter = () => {
        if (curColId !== null) {
            const filter = props.filterModel.find(f => f.colId === curColId);
            if (filter?.filterType === 'date') {
                props.onFilterChange(curColId, dateRange.start?.valueOf() || null, dateRange.end?.valueOf() || null);
            } else if (filter?.filterType === 'text') {
                props.onFilterChange(curColId, null, null, textFilter);
            } else if (filter?.filterType === 'uuid') {
                props.onFilterChange(curColId, null, null, textFilter);
            }
        }
        handleCloseModal();
    };

    useEffect(() => {
        if (curColId) {
            const filter = props.filterModel.find(f => f.colId === curColId);
            if (filter?.filterType === 'date') {
                const startDt = filter.dateFrom;
                if (startDt) {
                    setDateRange(prev => ({ ...prev, start: dayjs(startDt) }));
                }
                const endDt = filter.dateTo;
                if (endDt) {
                    setDateRange(prev => ({ ...prev, end: dayjs(endDt) }));
                }
            }
        }
    }, [curColId, props.filterModel]);

    useEffect(() => {
        if (dateRange.start === null && dateRange.end !== null) {
            setError('missing start date');
        } else if (dateRange.start !== null && dateRange.end === null) {
            setError('missing end date');
        } else if (dateRange.start && dateRange.end && dateRange.start.isAfter(dateRange.end)) {
            setError('start date should not be later than end date');
        } else {
            setError(null);
        }
    }, [dateRange]);

    const filterType = props.filterModel.find(f => f.colId === curColId)?.filterType;

    return (
        <>
            <div className="flex grow items-center gap-2">
                <span className="text-md font-medium">Filter by </span>
                {props.filterModel.map(filter => {
                    let filterValue = '';
                    const filterValueClass = 'text-green-600';
                    if (filter.filterType === 'date') {
                        const dateFrom = filter.dateFrom ? new Date(filter.dateFrom).toISOString().split('T')[0] : '';
                        const dateTo = filter.dateTo ? new Date(filter.dateTo).toISOString().split('T')[0] : '';
                        filterValue = dateFrom || dateTo ? `${dateFrom} - ${dateTo}` : '';
                    } else if (filter.filterType === 'text') {
                        filterValue = filter.filter || '';
                    } else {
                        const sequence = props.companySequenceArray.find(seq => seq.sequenceId === filter.id);
                        filterValue = sequence?.sequenceLabel || '';
                    }
                    return (
                        <button
                            key={filter.colId}
                            className={`flex gap-1 rounded ${filterValue ? 'border-2 border-blue-500' : 'border'} border-gray-400 px-1 text-gray-600`}
                            onClick={() => handleOpenModal(filter.colId)}
                        >
                            {filter.label} <span className={filterValueClass}>{filterValue}</span>
                        </button>
                    );
                })}
            </div>
            <Modal open={curColId !== null} onClose={handleCloseModal}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: 'white',
                        margin: 'auto',
                        marginTop: '10%',
                        width: '300px',
                        borderRadius: '8px'
                    }}
                >
                    {filterType === 'date' ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="mb-4">
                                <DateTimePicker
                                    label="Start Date"
                                    value={dateRange.start}
                                    onChange={newValue => setDateRange(prev => ({ ...prev, start: newValue }))}
                                />
                            </div>
                            <div className="mb-4">
                                <DateTimePicker
                                    label="End Date"
                                    value={dateRange.end}
                                    onChange={newValue => setDateRange(prev => ({ ...prev, end: newValue }))}
                                />
                            </div>
                        </LocalizationProvider>
                    ) : filterType === 'text' ? (
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="text-filter-label" shrink>
                                Select Option
                            </InputLabel>
                            <Select
                                labelId="text-filter-label"
                                value={textFilter || ''}
                                onChange={e => setTextFilter(e.target.value === '' ? null : e.target.value)}
                                label="Select Option"
                                notched
                            >
                                {
                                    // add (None) for other select options, except sequenceId since we force to select a sequence, use the latest sequence if it'snull
                                    curColId !== 'sequenceId' && (
                                        <MenuItem value="">
                                            <em>(None)</em>
                                        </MenuItem>
                                    )
                                }
                                {(() => {
                                    return props.intakeStatusArray.map(option => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ));
                                })()}
                            </Select>
                        </FormControl>
                    ) : filterType === 'uuid' ? (
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="uuid-filter-label" shrink>
                                Select Sequence
                            </InputLabel>
                            <Select
                                labelId="uuid-filter-label"
                                value={textFilter || ''}
                                onChange={e => {
                                    setTextFilter(e.target.value === '' ? null : e.target.value);
                                }}
                                label="Select Sequence"
                                notched
                            >
                                {props.companySequenceArray.map(sequence => (
                                    <MenuItem key={sequence.sequenceLabel} value={sequence.sequenceId}>
                                        {sequence.sequenceName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                    <div className="flex flex-col gap-1">
                        <div className="text-sm text-red-600"> {error && <span>{error}</span>}</div>
                        <div className="flex justify-end gap-2">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClearModal}
                                disabled={filterType === 'uuid'}
                            >
                                Clear
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApplyFilter}
                                disabled={error !== null}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Filter;
